<template>
  <div class="body" v-loading="showLoading">
    <el-form
      v-if="configForm"
      ref="detailForm"
      size="medium"
      :model="configForm"
      :rules="rules"
      label-position="right"
      label-width="135px"
      class="form small-form"
    >
      <div class="modular">
        <p class="modular-title" style="margin-bottom: 15px">基础信息</p>
        <div class="modular-content">
          <el-form-item label="模板名称：" prop="name">
            <el-input v-model="configForm.name" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="模板图标：" prop="icon">
            <!--            <single-media-wall v-model="configForm.icon" :width="60" :height="60" add-text="" />-->
            <el-popover
              v-model="isShowTemplateIcons"
              placement="bottom-start"
              title="模板图标"
              :visible-arrow="false"
              popper-class="template-icon"
              trigger="click"
            >
              <template #reference>
                <div class="icon-selector">
                  <add-button
                    v-if="!configForm.icon"
                    :width="60"
                    :height="60"
                    text=""
                    border-style="solid"
                  />
                  <options-image
                    v-else
                    class="cursor-pointer"
                    :src="configForm.icon"
                    :width="60"
                    :height="60"
                    hide-delete
                    hide-preview
                  />
                </div>
              </template>
              <div class="icon-grid">
                <div
                  @click="chooseTemplateIcon(icon)"
                  class="icon-grid-item cursor-pointer flex"
                  v-for="icon in iconList"
                  :key="icon.id"
                  :title="icon.title"
                >
                  <el-image class="flex-1" :src="icon.url" :alt="icon.title">
                    <template #error
                      ><div class="el-image__error">
                        {{ icon.title }}
                      </div></template
                    >
                  </el-image>
                </div>
                <!-- 如需要提供给用户自己上传的入口 -->
                               <div class="icon-grid-item cursor-pointer no-hover">
                <!--&lt;!&ndash;                  <add-button text="" :width="40" :height="40" />&ndash;&gt;-->
                                 <single-media-wall media-data="" :border-radius="4" :width="40" :height="40" add-text="" @handleMediaData="configForm.icon = $event" />
                               </div>
              </div>
            </el-popover>
          </el-form-item>
          <el-form-item label="模板状态：" prop="status">
            <div>
              <el-radio v-model="configForm.status" :label="1">启用</el-radio>
              <el-radio v-model="configForm.status" :label="0">禁用</el-radio>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title" style="margin-bottom: 15px">表单设置</p>
        <div class="modular-content">
          <el-form-item label="表单模板：">
            <template
              v-if="
                configForm.form_configs === 1 || configForm.form_configs.length
              "
            >
              <el-button disabled>已设置</el-button>
              <el-button type="text" @click="formDesign">修改</el-button>
            </template>
            <el-button v-else type="primary" @click="formDesign"
              >设置</el-button
            >
          </el-form-item>
        </div>
      </div>
    </el-form>

    <FixedActionBar>
      <el-button @click="$router.back()">取消</el-button>
      <el-button type="primary" @click="handleSave" :loading="saveLoading"
        >保存</el-button
      >
    </FixedActionBar>
  </div>
</template>

<script>
/* api */
import { allCategory } from '@/modules/approval/api/category-list'
import {
  allOrganizations,
  getTemplateDetail,
  saveTemplateDetail,
} from '@/modules/venue/api/template-detail'
/* 通用组件 */
import FixedActionBar from '@/base/layout/FixedActionBar'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
import MemberSelector from '@/modules/common/components/MemberSelector'
/* 页面组件 */
import SelectedUsers from '@/modules/approval/components/template-detail/SelectedUsers'
import AddButton from '@/base/components/Base/AddButton'
import OptionsImage from '@/base/components/Media/OptionsImage'
/* 工具 */

export default {
  components: {
    OptionsImage,
    AddButton,
    SelectedUsers,
    MemberSelector,
    SingleMediaWall,
    FixedActionBar,
  },
  data() {
    return {
      showLoading: true,
      saveLoading: false,
      configForm: {
        id: '',
        name: '',
        icon: '',
        category_id: '',
        status: 1, // 0： 禁用，1:启用
        form_configs: [], // 新增时可传配置数组，编辑时忽略
        apply_for_all: 1, // 申请人设置（0: 指定成员，1:所有人）
        apply_users: [], // 申请人设置，指定成员时的 ID 数组
        approval_text: '审批人', // 审批人设置，自定义文案（默认：审批人）
        approval_for: 1, // 审批人设置，成员设置（1:指定成员，2:本人，3:自选）
        approval_users: [], // 审批人设置，指定成员或自选指定范围时使用，自选时为空代表不限范围
        approval_multiple: 0, // 审批人设置，自选时才有意义（0:单选，1:多选）
        approval_method: 0, // 审批人设置，审批方式（0：或签，1：会签，2：依次审批）
        copy_text: '抄送人', // 抄送人设置，自定义文案（默认：抄送人）
        copy_for: 1, //     抄送人设置，成员设置（1：指定成员，2：申请人本人，3：申请人自选）
        copy_users: [], // 抄送人设置，指定的成员 ID 数组
        apply_limit: 0, // 申请限制（0：无限制，1：周期限制）
        circle_limit_config: {
          //周期限制配置
          num: 2, // 周期数
          unit: 'month', // 周期单位 day,month,year
          times: 2, // 限制次数
        },
        auto_approval: 1, // 异常处理 (1 : 自动同意，2:转交指定成员处理）
        auto_users: [], // 异常处理指定的成员 ID 数组
        need_sign: 1, // 审批时是否需要签名
        apply_organizations: [],
      },
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        category_id: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
      },
      categories: [],

      showMemberSelector: false,
      type: 'apply',
      noApprovalLimit: true,
      noCopyLimit: true,

      organizations: [],

      isShowTemplateIcons: false,
      iconList: [
        {
          id: 0,
          title: '出差',
          url: 'https://superres.shetuan.pro/cos/admin/images/20230328/7b22a865993655d4c2ea6c09ac0bf38c.png',
        },
        {
          id: 1,
          title: '请假',
          url: 'https://superres.shetuan.pro/cos/admin/images/20230328/4086cdfa4b4a574cf833991ed2932286.png',
        },
        {
          id: 2,
          title: '补卡',
          url: 'https://superres.shetuan.pro/cos/admin/images/20230328/8bfda767a092e3cb64dbbbf6d11ee011.png',
        },
        {
          id: 3,
          title: '外勤',
          url: 'https://superres.shetuan.pro/cos/admin/images/20230328/8d4c54c7e0b24b86d76337a60d7f9398.png',
        },
        {
          id: 4,
          title: '加班',
          url: 'https://superres.shetuan.pro/cos/admin/images/20230328/c7e78342d87589561a6348c9a5a54710.png',
        },
      ],
    }
  },
  computed: {
    disabledUsers() {
      if (this.type === 'approval') return this.configForm.approval_users
      if (this.type === 'copy') return this.configForm.copy_users
      if (this.type === 'auto') return this.configForm.auto_users
      return this.configForm.apply_users
    },
  },
  created() {
    allCategory()
      .then((res) => {
        this.categories = res.data.filter((el) => !!el.id)
      })
      .catch(() => {})
    allOrganizations({ is_show_none: 0, is_all: 1 })
      .then((res) => {
        this.organizations = res.data
      })
      .catch(() => {})
    if (!this.$route.params.id) {
      localStorage.setItem(
        'form_configs',
        JSON.stringify({
          form_configs: [],
        })
      )
      this.showLoading = false
      return
    }
    getTemplateDetail(this.$route.params.id)
      .then((res) => {
        this.configForm = res.data
        this.noApprovalLimit = !this.configForm.approval_users?.length
        this.showLoading = false
      })
      .catch(() => {
        this.showLoading = false
      })
  },
  mounted() {
    let that = this
    // 任何页面修改了localStorage的值，都会自动触发其他页面中的storage事件
    // 只要storage一变化我们读取localStorage中对应的值显示到页面上
    window.addEventListener('storage', function (e) {
      // console.log(e, localStorage.getItem('form_configs'))
      const storageDataStr = localStorage.getItem('form_configs') || '{}'
      that.configForm.form_configs =
        JSON.parse(storageDataStr).form_configs ||
        (this.$route.params.id ? 0 : [])
    })
  },
  beforeDestroy() {
    window.removeEventListener('storage', function (e) {
      // console.log('remove listener', localStorage.getItem('form_configs'))
      const storageDataStr = localStorage.getItem('form_configs') || '{}'
      that.configForm.form_configs =
        JSON.parse(storageDataStr).form_configs ||
        (this.$route.params.id ? 0 : [])
    })
  },
  methods: {
    chooseTemplateIcon(obj) {
      this.configForm.icon = obj.url
      this.isShowTemplateIcons = false
    },
    handleExportCatalogChange() {
      // this.$refs.CascaderRef.dropDownVisible = false
    },
    validateForm(callback) {
      this.$refs.detailForm.validate((valid) => {
        if (valid) callback()
      })
    },
    handleSave() {
      this.validateForm(() => {
        this.saveLoading = true
        const {
          form_configs,
          apply_users,
          approval_users,
          copy_users,
          ...needed
        } = this.configForm
        const postData = {
          /* 原样保存的字段 */
          ...needed,
          id: this.$route.params.id || '0',
          /* 需要保存前处理的字段 */
          /* 新增才有的字段 */
          ...(this.$route.params.id
            ? {}
            : {
                form_configs,
              }),
        }
        console.log(postData, '(((((((((((((((')
        saveTemplateDetail({ 
          ...postData,
        })
          .then((res) => {
            this.$message.success(res.msg)
            this.saveLoading = false
            this.$router.back()
          })
          .catch((err) => {
            this.saveLoading = false
          })
      })
    }, 
    handleAddUsers(e) {
      if (this.type === 'approval') {
        this.configForm.approval_users.push(...e)
      } else if (this.type === 'copy') {
        this.configForm.copy_users.push(...e)
      } else if (this.type === 'auto') {
        this.configForm.auto_users.push(...e)
      } else {
        this.configForm.apply_users.push(...e)
      }
    },
    formDesign() {
      let routerData = this.$router.resolve({
        name: 'VenueTmplDesign',
        params: { id: this.$route.params.id || 0 },
      })
      window.open(routerData.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  min-height: 400px;
  padding-top: $space;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(auto-fit, 40px);
  grid-gap: 10px;

  .icon-grid-item {
    padding: 8px;

    &.no-hover {
      padding: 0;
    }

    &:not(.no-hover):hover {
      background-color: $borderLighter;
      border-radius: 4px;
    }
  }

  ::v-deep.el-image__error {
    background-color: unset;
    font-size: 10px;
    width: 100%;
    height: 100%;
  }
}
</style>
<style>
.el-popper.template-icon[x-placement^='bottom'] {
  margin-top: 0;
}
</style>
